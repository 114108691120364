import * as React from 'react';
import * as Styled from './headerWithButtonImageRightStyle';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlhref,
  isYoutubeLink,
} from '../../utils';

const HeaderWithButtonImageRight = ({ sectionData, location }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HR]: () => <Styled.HorizontalBreak />,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.HeaderH1>{children}</Styled.HeaderH1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.UnorderedList>{children}</Styled.UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItems>{children}</Styled.ListItems>
      ),
      [INLINES.HYPERLINK]: (node, children) =>
        isYoutubeLink(node.data.uri) ? (
          <Styled.VideoContainer>
            <Styled.Iframe src={node.data.uri} frameborder="0"></Styled.Iframe>
          </Styled.VideoContainer>
        ) : (
          <Styled.ButtonStyle
            href={
              isExternalUrlhref(node?.data?.uri)
                ? node?.data?.uri
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                  )
            }
            target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
          >
            {children}
          </Styled.ButtonStyle>
        ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.ButtonStyle href={asset?.node?.file?.url} target={'_blank'}>
              {children}
            </Styled.ButtonStyle>
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          asset?.node?.description ? (
            <Styled.LinkStyle
              href={
                isExternalUrlhref(asset?.node?.description)
                  ? asset?.node?.description
                  : addTrailingSlash(
                      process.env.GATSBY_DOMAIN_URL + asset?.node?.description
                    )
              }
              target={
                isExternalUrl(asset?.node?.description) ? '_blank' : '_self'
              }
            >
              <Styled.ImageStyle
                src={asset.node.file.url}
                alt={asset.node.title}
              />
            </Styled.LinkStyle>
          ) : (
            <Styled.ImageStyle
              src={asset.node.file.url}
              alt={asset.node.title}
            />
          )
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  React.useEffect(async () => {
    if (sectionData?.elementId === location?.hash?.replace('#', '')) {
      const waitedID = await new Promise((resolve) => {
        document?.getElementById(sectionData?.elementId) && resolve(true);
      });
      if (waitedID) {
        setTimeout(() => {
          window.location.href = `#${sectionData?.elementId}`;
        }, 500);
      }
    }
  }, [sectionData?.elementId]);

  return (
    <Styled.MainDiv>
      {sectionData?.elementId?.trim() && (
        <Styled.SpanStyle
          id={sectionData?.elementId?.trim()}
        ></Styled.SpanStyle>
      )}
      {sectionData?.header?.trim() && (
        <Styled.HeaderH1>{sectionData.header}</Styled.HeaderH1>
      )}
      {sectionData?.header?.trim() && (
        <Styled.HeaderH2>{sectionData.subText}</Styled.HeaderH2>
      )}
      {sectionData?.contentDetails &&
        documentToReactComponents(
          JSON.parse(sectionData?.contentDetails?.raw),
          optionsMainStyle
        )}
    </Styled.MainDiv>
  );
};
export default HeaderWithButtonImageRight;
